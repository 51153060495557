
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.pdp-product-description-strict {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 15px 0 15px;
  cursor: pointer;
}

.strict-restriction {
  p {
    font-size: 14px;
    font-weight: typography.$light;
    line-height: 22px;
    a {
      color: color.$primary-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.modal-dialog {
  max-width: 600px;
  margin-top: 70px;
}

.shipping-restrictions-wrap {
  .shipping-restrictions-modal-header {
    h4 {
      font-size: 22px;
    }
  }
}

.strict-restriction-wrap {
  font-size: 12px;
  line-height: 16px;
}
