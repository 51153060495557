
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.pagination {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  gap: 1px;
  align-items: center;
}

.pagination a {
  color: color.$black-color;
  padding: 8px 8px;
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 16px;
  text-decoration: none;
}

.pagination a.active {
  color: color.$white-color;
  background-color: color.$pagination-color;
  border-color: color.$pagination-color;
  font-weight: typography.$bold;
  border-radius: 50%;
}

.pagination a.prev,
.pagination a.next {
  font-weight: typography.$light;
}

.pagination a.hidden {
  display: none;
}

.pagination span {
  padding: 8px 8px;
  cursor: pointer;
}

.icn-chevron-rgt {
  font-style: normal;
  &::before {
    content: '\e917';
  }
}

.icn-chevron-lft {
  font-style: normal;
  &::before {
    content: '\e914';
  }
}

.page {
  color: color.$black-color;
  padding: 8px 8px;
  width: 33px;
  height: 33px;
  text-align: center;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;
}

.active {
  color: color.$white-color;
  background-color: color.$pagination-color;
  border-color: color.$pagination-color;
  font-weight: typography.$bold;
  border-radius: 50%;
  cursor: pointer;
}
