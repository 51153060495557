
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.search-item-list-view {
  margin-bottom: 25px;
  clear: both;
  &:last-child {
    margin-bottom: 0;
  }
  @include breakpoint('large') {
    display: flex;
    gap: 20px;
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 35px;
    }
  }
}
.search-item-image {
  float: left;
  margin-right: 20px;
  padding-right: 15px;
  margin-bottom: 40px;
  @include breakpoint('large') {
    float: none;
    margin-right: 0;
    margin-bottom: 0;
    padding-right: 0;
  }
  &.video {
    img {
      width: 180px;
    }
  }
  img {
    height: auto;
    width: 180px;
    @include breakpoint('large') {
      width: auto;
    }
  }
  .worksheet,
  .lessonPlan {
    img {
      width: 180px;
      height: auto;
      border: 1px solid color.$light-gray;
      @include breakpoint('large') {
        width: 126px;
      }
    }
  }
  .activity {
    img {
      width: auto;
      height: auto;
    }
  }
}
.search-title {
  font-size: 14px;
  color: color.$black;
  font-weight: typography.$bold;
  &:hover {
    text-decoration: underline;
  }
}
.view-details {
  color: color.$primary-color;
  text-transform: capitalize;
  &:hover {
    text-decoration: underline;
  }
}

.play-video {
  cursor: pointer;
  color: color.$primary-color;
  &.link {
    margin-right: 10px;
  }
}
.video-player {
  width: 100%;
}

.play-video-wrapper {
  > div:first-child {
    padding: 0;
    h4:empty {
      display: none;
    }
  }
}
