
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.select-product {
  position: relative;
  padding-bottom: 4px;
}
.form-select-wrapper {
  margin-bottom: 20px;
  label {
    font-weight: typography.$bold;
    padding-bottom: 5px;
    margin-bottom: 5px;
    display: block;
  }
  .select-box-wrp {
    position: relative;
    .back-drop {
      position: fixed;
      top: 0;
      right: -1px;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 9;
      touch-action: none;
    }
  }
  .select-option {
    font-size: 20px;
    line-height: 34px;
    background-color: color.$white-color;
    border: 1px solid color.$light-gray;
    color: color.$black-color;
    padding: 6px 10px;
    font-weight: typography.$light;
    min-width: 86px;
    width: 100%;
    border-radius: 4px;
    user-select: none;
    text-align: left;
    &:hover {
      transform: scale(1);
      background-color: color.$white-color;
      border-color: color.$light-gray;
      color: color.$black-color;
    }
    @include breakpoint('medium') {
      font-size: 14px;
      line-height: 1.42857143;
    }
    .caret-icon {
      display: inline-block;
      opacity: 1;
      visibility: visible;
    }
  }
  .error {
    border-color: color.$red;
  }
  .dropDown-val {
    left: 5%;
    width: 90%;
    height: 100%;
    max-height: 400px;
    border: 0;
    line-height: 30px;
    bottom: 0;
    top: auto;
    position: fixed;
    transform: translateY(100%);
    transition: transform 0.2s;
    z-index: 99;
    padding: 10px;
    background-color: color.$white-color;
    line-height: 1.42857143;
    overflow-x: hidden;
    border-radius: 4px 4px 0 0;
    display: none;
    @include breakpoint('medium') {
      position: absolute;
      top: 0;
      bottom: auto;
      left: 0;
      z-index: 2;
      border: 2px solid color.$grey1;
      width: 120%;
      margin-top: -3px;
      line-height: 1.42857143;
      max-height: 250px;
      border-radius: 4px 4px 0 0;
      display: none;
      transform: translateY(0);
      height: auto;
      padding: 0 10px 10px;
      li:first-child {
        position: sticky;
        z-index: 1;
        top: 0;
        background: color.$white-color;
        padding-top: 10px;
      }
    }
    @include breakpoint('large') {
      width: 140%;
    }
    @include breakpoint('xlarge') {
      width: 130%;
    }
  }

  .color {
    width: 90%;
    @include breakpoint('medium') {
      width: 100%;
    }
  }

  .show-dropdown {
    transform: translateY(0);
  }
  .select-option-disabled {
    color: color.$light-gray;
    background-color: color.$gray-f0f0f1;
    border-color: color.$gray-f0f0f1;
    pointer-events: none;
    cursor: not-allowed;
    min-width: 86px;
    padding: 6px 10px;
    opacity: 0.65;
    border: 0;
    width: 100%;
    text-align: left;
  }
  .color-dropDown {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    top: 1px;
  }
  .white-color {
    box-shadow:
      -1px 0 color.$light-gray,
      0 1px color.$light-gray,
      1px 0 color.$light-gray,
      0 -1px color.$light-gray;
  }
  .multi-color {
    background-image: url(https://img.lakeshorelearning.com/is/image/OCProduction/assorted);
    background-size: 18px 18px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    top: 2px;
  }
}
