
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.stock-alert-message {
  color: color.$red-neon;
  margin-bottom: 10px;
}
