
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.inventory-alert {
  color: color.$red-neon;
  font-size: 14px;
  line-height: 22px;
  display: block;
  margin-bottom: 16px;
}
.error-msg {
  color: color.$red;
  font-size: 14px;
  margin-top: -12px;
  margin-bottom: 10px;
  display: block;
}
