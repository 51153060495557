
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.productWrapper {
  display: flex;
  gap: 15px;
  position: relative;
  flex-direction: column;
  @include breakpoint('large') {
    flex-direction: row;
    margin-top: 7px;
  }
}

.songListContainer {
  margin-top: -5px;
}

.productWrapper .badge {
  width: 102px;
  height: 85px;
  position: absolute;
  bottom: 0px;
  right: 0;
}

.galleryContainer {
  position: relative;
  width: 100%;
  height: auto;
}

.imageGalleryImage {
  position: relative;
  width: 50%;
  height: auto;
}

.active {
  border: 2px solid color.$primary-color;
}

.left-images-wrap {
  position: relative;
  height: auto;
  overflow: hidden;
  margin-bottom: 50px;
  min-width: 65px;
  @include breakpoint('large') {
    height: 360px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin: 0 30px;
  }
}

.others {
  border: 2px solid color.$light-gray;
}

.thumbnailWrapper {
  cursor: pointer;
}

.thumbnailContainer {
  width: 108px;
  height: 81px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modalContent {
  background: color.$white-color;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  height: 80%;
}

.closeButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: firebrick;
  color: color.$white-color;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 999;
}

.arrowContainer {
  position: absolute;
  bottom: 0px;
  left: 50px;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  &.tab {
    @media screen and (max-width: 991px) {
      width: 100%;
      left: 0;
      transform: unset;
      .arrowUp {
        position: absolute;
        left: 0;
        bottom: 40px;
        transform: rotate(-180deg);
      }
      .arrowDown {
        position: absolute;
        right: 0;
        bottom: 40px;
        transform: rotate(0deg);
      }
    }
  }
}

.arrow {
  background: rgba(0, 0, 0, 0.5);
  background: variables.$background-lakeshore-sprites -486px -30px/300px 300px;
  @include breakpoint('medium') {
    background: variables.$background-lakeshore-sprites -480px -30px/300px 300px;
  }
  @include breakpoint('large') {
    background: variables.$background-lakeshore-sprites -480px -30px/300px 300px;
  }
  @include breakpoint('mlarge') {
    background: variables.$background-lakeshore-sprites -480px -30px/300px 300px;
  }
  @include breakpoint('xlarge') {
    background: variables.$background-lakeshore-sprites -486px -30px/300px 300px;
  }
  color: white;
  border: none;
  cursor: pointer;
  height: 30px;
  width: 16px;
  text-align: center;
  z-index: 1;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.arrowUp {
  margin-right: 25px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.view-larger-wrap {
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  display: none;
  @include breakpoint('medium') {
    display: flex;
    margin-top: 20px;
  }
  .view-larger-button {
    color: color.$primary-color;
    font-weight: typography.$bold;
    padding: 0;
    border: 0;
    position: relative;
    cursor: pointer;
    &:hover {
      transform: none;
    }
  }
}

.icn-enlarge {
  color: color.$primary-color;
  font-size: 22px;
  margin-right: 5px;
  cursor: pointer;
  &::before {
    content: '\e920';
  }
}

.product-modal-body-wrapper {
  display: flex;
  width: 100%;
  gap: 20px;
}

.product-modal-body {
  position: relative;
  @include breakpoint('medium') {
    height: 620px;
  }
  @include breakpoint('large') {
    height: auto;
  }
  .arrowContainer {
    bottom: unset;
  }
}

.thumbnail-item {
  margin-bottom: 10px;
  cursor: pointer;
}

.thumbnail-container {
  position: relative;
  transition: transform 0.3s ease;
  @include breakpoint('medium') {
    display: flex;
    flex-direction: row;
    gap: 17px;
    height: auto !important;
    margin-top: 0px;
  }
  @include breakpoint('large') {
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: auto !important;
    margin-top: 0px;
  }
  &.modal {
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
}
.spaceT-12 {
  margin-top: 12px;
}
.thumbnail-item {
  margin: 0px 0px 8px 0px;
  @include breakpoint('medium') {
    width: 80px;
    flex-shrink: 0;
  }
  @include breakpoint('large') {
    width: 112px;
    flex-shrink: 0;
  }
  img {
    @include breakpoint('medium') {
      width: 100%;
      height: auto;
    }
  }
}

.modal-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    margin: 0 0 50px;
    @include breakpoint('medium') {
      width: 500px;
      height: auto;
    }
    @include breakpoint('large') {
      width: 770px;
      height: auto;
      margin-top: 30px;
    }
    @include breakpoint('xlarge') {
      width: 800px;
      height: auto;
      margin-top: 30px;
    }
  }
}

.icn-x-close-lgt-circle {
  position: relative;
  color: #838587;
  &::before {
    content: '\e955';
    position: absolute;
    font-size: 28px;
    left: -32px;
    top: -5px;
  }
}
.icn-x-close-text {
  font-size: 14px;
  color: #838587;
}

.listen-section-wrap {
  display: flex;
  align-items: center;
  color: color.$primary-color;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  text-align: center;
  @include breakpoint('medium') {
    margin-top: 15px;
  }
  .listen-button {
    color: color.$primary-color;
    font-weight: typography.$light;
    padding: 0;
    min-width: auto;
    margin-left: 5px;
    &:hover {
      transform: none;
    }
  }
}

.content-wrap {
  position: relative;
}

.icn-volume-up {
  &::before {
    content: '\f028';
  }
}

.disabled {
  opacity: 0.2;
}

.modal-dialog-audio-wrapper {
  .modal-dialog-audio {
    max-width: 600px;
    margin: 20px;
    @media (min-width: 767px) {
      margin: 70px auto;
      max-width: 600px;
    }
  }
}

.modal-dialog-wrapper {
  .modal-dialog {
    margin: 0px auto;
    max-width: 1140px;
    @include breakpoint('small') {
      margin: 70px 20px;
      width: 720px;
    }
    @include breakpoint('medium') {
      margin: 70px auto;
    }
    @include breakpoint('large') {
      margin: 70px auto;
      width: 940px;
    }

    @include breakpoint('xlarge') {
      margin: 70px auto;
      width: 100%;
    }
  }
}

.media-container {
  audio {
    width: 100%;
    margin-bottom: 15px;
  }
}

.modal-dialog-audio {
  .audio-playlist-title {
    font-size: 18px;
    font-weight: typography.$bold;
    margin-bottom: 10px;
    color: color.$black-color;
    margin-top: 25px;
  }
}

.audio-playlist-name {
  background: rgb(176, 224, 230);
  line-height: 23px;
  display: block;
  margin-bottom: 5px;
  color: color.$primary-color;
  font-weight: typography.$bold;
  padding-left: 5px;
  margin-bottom: 20px;
}

.lyrics-wrapper {
  border: 1px solid #000;
  border-radius: 10px;
  width: 100%;
  height: 139px;
  padding: 15px;
  position: relative;
  margin: 4px 0 0px;
}

.modal-dialog-audio {
  .lyrics-wrapper-content {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    overflow-y: scroll;
    height: 110px;
    padding-right: 20px;
    p {
      margin: 0 0 15px;
      font-weight: typography.$light;
      font-size: 14px;
      color: color.$black-color;
      br {
        content: 'A';
        display: block;
      }
    }
  }
  .lyrics-title {
    font-size: 14px;
    font-weight: typography.$bold;
    margin-bottom: 10px !important;
    color: rgb(2, 2, 2);
    margin-bottom: 15px;
  }
  .song-list {
    cursor: pointer;
    max-height: 190px;
    overflow-y: auto;
    padding-left: 10px;
    margin-top: 15px;
    display: inline-block;
    width: 100%;
    p {
      margin-left: 15px;
      color: color.$primary-color;
      font-size: 14px;
      span {
        font-weight: typography.$light;
      }
    }
  }

  .audio-modal-footer {
    color: color.$primary-color;
    padding: 15px 0 0 5px;
    cursor: pointer;
    display: inline-block;
    span {
      margin-left: 5px;
      font-weight: typography.$light;
      font-size: 14px;
    }
    .icn-x-close-lgt {
      &::before {
        content: '\e957';
        font-style: normal;
      }
    }
  }

  .lyrics-title-mid {
    font-weight: typography.$bold;
    text-align: center;
    font-size: 14px;
    color: color.$black-color;
    background: color.$white-color;
    width: 55px;
    margin: -9px auto 5px;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    z-index: 1;
  }
  .previous-next-container {
    margin-bottom: 15px;
    span {
      width: 50%;
      display: inline-block;
      height: 25px;
      text-align: center;
      border: 0.8px solid rgb(0, 0, 0);
      border-radius: 2px;
      background: #efefef;
      &:hover {
        cursor: pointer;
        background-color: #e5e4e2;
      }
      &.arrow-next {
        &::before {
          content: '\f061';
        }
      }
      &.arrow-previous {
        &::before {
          content: '\f060';
        }
      }
    }
  }
}

.lifetime-warranty-wrap {
  position: absolute;
  bottom: 60px;
  right: 50px;
  z-index: 2;
  pointer-events: none;
  @media screen and (max-width: 991px) {
    bottom: 145px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.icn-play {
  font-style: normal;
  margin-right: 7px;
  position: relative;
  margin-left: -18px;
  &::before {
    content: '\f04b';
  }
}

//  Mobile view css
.product-view-content {
  border-radius: 0;
  border: none;
}

.carousel-wrapper {
  margin-bottom: 25px;
  @include breakpoint('medium') {
    margin-bottom: 40px;
  }
  .close-icon-modal {
    background: url('/assets/images/remove_x.svg') no-repeat center center;
    background-size: 15px 15px;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: none;
    min-width: auto;
    padding: 20px;
  }
  .indicator-wrapper-modal {
    bottom: 90px !important;
  }
}

.product-view-modal-wrapper {
  .product-modal-dialog {
    margin: 0px;
    position: fixed;
    width: 100%;
    .mobile-modal-body-wrapper {
      padding: 0px;
    }
  }
}

.product-modal-open-popup {
  height: 540px;
  overflow: hidden;
  @include breakpoint('medium') {
    height: 580px;
  }
  @include breakpoint('large') {
    height: 550px;
  }
  .thumbnail-item {
    @include breakpoint('medium') {
      width: 112px;
      margin: 0px;
    }
    @include breakpoint('large') {
      margin-bottom: 10px;
    }
  }
}

.listen-button-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
