
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.modalBody {
  padding: 16px;
  p {
    margin-bottom: 10px;
  }
}

.modalContent {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: color.$white-color;
  background-clip: padding-box;
  border-radius: 8px;
  outline: 0;
  margin-left: auto;
  margin-right: auto;
  .closeIcon {
    right: -12px;
    top: -12px;
    width: 26px;
    height: 26px;
    background: variables.$background-lakeshore-sprites 0 -78px / 260px 260px;
    font-size: 0;
    min-width: auto;
    position: absolute;
    padding: 0;
    border: none;
    @include breakpoint('medium') {
      width: 35px;
      height: 35px;
      background: variables.$background-lakeshore-sprites 0 -105px / 350px 350px;
    }
    &:hover {
      background-color: transparent;
    }
  }
  .modalTitle {
    font-size: 22px;
    color: color.$white-color;
    font-weight: typography.$bold;
  }
}

.modal-header {
  background: color.$primary-color;
  padding: 15px;
  border-radius: 6px 6px 0 0;
}

.modalClass {
  display: block;
}
