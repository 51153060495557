
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.checkboxWrapper {
  margin-bottom: 10px;
  position: relative;
  label {
    margin: 0;
  }
}

.inputPassword {
  position: relative;
}
.createAccountSection {
  border-top: 1px solid color.$light-gray;
  padding-top: 15px;
  span {
    margin-top: 10px;
    font-size: 12px;
    display: block;
  }
}

.password-icon {
  &.showIcon {
    background: variables.$background-lakeshore-sprites -306px -34px / 340px
      340px;
  }
  &.hideIcon {
    background: variables.$background-lakeshore-sprites -272px -34px/340px 340px;
  }
  cursor: pointer;
  width: 34px;
  height: 34px;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 30px;
}

.modalWrapperSection {
  .modal-content {
    h4 {
      font-size: 22px;
      margin-bottom: 0;
    }
  }
  .modalDialogPassword {
    max-width: 650px;
  }
  .modalDialogWrapper {
    margin: 20px 15px;
    max-width: 100%;
    input {
      height: 46px;
      font-weight: typography.$light;
    }
  }
  @include breakpoint('medium') {
    .modalDialogWrapper {
      max-width: 600px;
      margin: 70px auto 0;
      input {
        height: auto;
      }
    }
  }
  .modalDialog {
    max-width: 100%;
    margin: 20px 15px;
    .error-msg {
      a {
        font-size: 14px;
        cursor: pointer;
        color: color.$primary-color;
        &:hover {
          color: color.$primary-color;
        }
      }
    }
    @include breakpoint('medium') {
      width: calc(100% - 30px);
      max-width: 375px;
      margin: 70px auto 0;
    }
    font-size: 14px;
    a {
      color: color.$primary-color;
      font-size: 11px;
      &:hover {
        text-decoration: underline;
      }
      &.membershipText {
        font-size: 14px;
      }
    }
  }
}

.popOverCreateStaySigned {
  font-size: 12px;
  padding: 7px;
  border: 1px solid color.$light-gray;
  border-radius: 5px;
  width: 100%;
  position: absolute;
  left: 0px;
  z-index: 11;
  background: color.$white-color;
  line-height: 16px;
  top: -115px;
  box-shadow: 1px 6px 10px #00000040;
  color: color.$black-color;
  &::after {
    width: 0;
    height: 0;
    content: '';
    bottom: -19px;
    position: absolute;
    left: 45%;
    border-top: 10px solid color.$white-color;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}

.popOverStaySigned {
  font-size: 11px;
  padding: 7px;
  border: 1px solid color.$light-gray;
  border-radius: 5px;
  width: 338px;
  position: absolute;
  left: -10px;
  z-index: 11;
  background: color.$white-color;
  line-height: 16px;
  top: -75px;
  box-shadow: 1px 6px 10px #00000040;
  &::after {
    width: 0;
    height: 0;
    content: '';
    bottom: -19px;
    position: absolute;
    left: 45%;
    border-top: 10px solid color.$white-color;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
}

.icnHelpOtl {
  margin-left: 5px;
  color: color.$black-color;
  cursor: pointer;
  &::before {
    content: '\e927';
  }
}

.error-msg {
  color: color.$red;
  font-size: 14px;
  margin: 0 0 7px;
  a {
    font-size: 14px;
    cursor: pointer;
    color: color.$primary-color;
    &:hover {
      text-decoration: underline;
      color: color.$primary-color;
    }
  }
}

.close-icon {
  background: url(/assets/images/typeahead-x.png) no-repeat right top;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 5px;
  top: 10px;
  cursor: pointer;
}

p {
  &.signUpText {
    font-size: 14px;
  }
}

.newUserAccount {
  font-weight: typography.$bold;
}

.disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.modalWrapperSection {
  .Inputerror {
    input {
      border-color: color.$red;
    }
  }
}

.signInModalButton {
  height: 45px;
  font-weight: typography.$bold;
  font-size: 16px;
}

.signInBottomWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
  @media all and (max-width: 767px) {
    display: block;
    button {
      width: 100%;
      margin-bottom: 20px;
      height: 45px;
    }
  }
}

.forgotPassLink {
  font-size: 14px;
  color: #007daa;
  margin-bottom: 3px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @include breakpoint('medium') {
    margin-bottom: 0;
  }
}

.forgotPassLinkRewardsEnabled {
  font-size: 11px;
}

@media all and (max-width: 767px) {
  .modalWrapperSection {
    .modalDialog {
      margin: 15px 20px;
    }
  }
}

.cancel-button {
  display: block;
  width: 100%;
}

.disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
