
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.morelink {
  cursor: pointer;
  outline: 0;
  display: inline-flex;
  align-items: center;
  color: color.$grey11;
  text-decoration: none;
  font-weight: typography.$bold;
}

.nowrap {
  white-space: nowrap;
  display: inline-block;
  margin-left: 0;
}

.icn-caret-lft,
.icn-caret-rgt {
  color: color.$red-neon;
  font-style: normal;
  display: inline-block;
  vertical-align: -2px;
  padding: 0 1px;
}

.icn-caret-rgt::before {
  content: '\e90b';
}

.read-text-button {
  &::before {
    content: '...\00a0\00a0Read More';
  }

  &::after {
    content: '';
  }

  &[aria-expanded='true'] {
    &::before {
      content: '';
    }

    &::after {
      content: 'Read Less';
    }

    .icn-caret-rgt::before {
      content: '\e90a';
      margin-left: 6px;
    }
  }
}
