
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.paypal-button-wrp {
  position: relative;
  flex: 1;
  border-radius: 50px;
  overflow: hidden;
  & > div > div {
    min-width: 115px !important;
  }
  .paypal-logo-button {
    position: absolute;
    pointer-events: none;
    height: 45px;
    background: #fff;
    border: 1px solid #000;
    z-index: 999;
    width: 100%;
    top: 0;
    text-align: center;
    line-height: 34px;
    border-radius: 50px;
    @include breakpoint('medium') {
      height: 41px;
    }
  }
}
