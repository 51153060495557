
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.pdp-product-description-badges-wrap {
  display: flex;
  flex-wrap: wrap;
  grid-area: 15px;
}

.pdp-product-description-badges {
  display: flex;
}

.pdp-product-description-badges {
  display: flex;
  gap: 4px;
  cursor: pointer;
  margin: 0 15px 12px 0;
  align-items: center;
  &.awards-badges {
    cursor: auto;
  }
  span {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: color.$black-color;
    margin: 0;
    max-width: 88px;
  }
}

.bages-modal-content {
  p {
    font-size: 14px;
    color: color.$black-color;
    line-height: 22px;
    a {
      color: color.$primary-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
