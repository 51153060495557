
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-price-range {
  font-size: 20px;
  font-weight: typography.$light;
  color: color.$black-color;
  margin-bottom: 18px;
  display: flex;
  @include breakpoint('medium') {
    font-size: 25px;
  }
  &.red {
    color: color.$red;
  }
  .green {
    color: color.$light-green;
  }
  .gsa-price {
    padding-right: 5px;
  }
  .sale {
    font-size: 12px;
    color: color.$gray2;
    line-height: 28px;
  }
}

.sale {
  font-size: 12px;
  color: color.$gray2;
  line-height: 28px;
}

.sale-price-wrp {
  align-items: baseline;
  display: flex;
  margin-bottom: 2px;
  gap: 9px;
}

.cart-price-wrp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.cart-price {
  font-size: 15px;
  font-weight: typography.$light;
  color: color.$black-color;
  display: flex;
  &.red {
    color: color.$red;
  }
}
