
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.orSection {
  color: color.$gray7;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.27px;
  &:before {
    content: '';
    height: 1px;
    background: color.$gray5;
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 0;
  }
  & > span {
    display: inline;
    padding: 0 15px;
    background: color.$white-color;
  }
}
