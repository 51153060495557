
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.checkFieldControl {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 25px;
  gap: 5px;
  .labelText {
    font-size: 11px;
    line-height: 15px;
    display: inline-block;
    color: color.$black-color;
    a {
      color: color.$primary-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .check-indicator {
    position: absolute;
    top: auto;
    left: auto;
    height: 17px;
    width: 17px;
    border: 1px solid color.$thin-gray;
    background: color.$white-color;
    cursor: pointer;
    border-radius: 15%;
    top: 0;
    left: 0;
    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 3px;
      width: 6px;
      height: 10px;
      border: solid color.$white-color;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  input {
    visibility: visible;
    opacity: 0;
    position: absolute;
    top: 0;
    border: 1px solid color.$thin-gray;
    cursor: pointer;
    width: 20px;
    height: 20px;
    &:checked {
      & ~ .check-indicator {
        background: color.$green-blue;
        border: none;
        &:after {
          display: block;
        }
      }
    }
  }
  .check-indicator-disabled {
    border: 1px solid color.$light-gray;
    background-color: color.$gray-f0f0f1;
    cursor: default;
  }
}

.disable-input {
  input {
    cursor: default;
  }
}

.checkFieldControl.sm {
  .labelText {
    font-size: 11px;
  }
}

.checkFieldControl.md {
  .labelText {
    font-size: 12px;
  }
}

.checkFieldControl.lg {
  .labelText {
    font-size: 14px;
    line-height: 18px;
  }
}
