
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.reward-details {
  text-decoration: underline;
  cursor: pointer;
}

.discount-promo-wrapper {
  margin: 10px 0;
  color: color.$primary-color;
}
.discount-promo-message {
  font-weight: typography.$bold;
  color: color.$primary-color;
}
.discount-promo-link {
  cursor: pointer;
  text-decoration: underline;
}

.pdp-promo-message-modal {
  max-width: 720px;
}
.terms-condition-wrapper {
  a {
    color: color.$primary-color;
    &:hover {
      text-decoration: underline;
    }
  }
}

.terms-condition-description {
  margin-bottom: 55px;
}
