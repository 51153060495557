
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.icn-help-otl {
  color: color.$grey10;
  vertical-align: middle;
  &::before {
    content: '\e927';
  }
}
.sale-info-message {
  color: color.$red-neon;
}
.sale-info-wrap {
  margin-bottom: 10px;
}
