
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.account-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 56px;
  padding: 0px 2px 0px 5px;
  @media screen and (min-width: 376px) {
    padding: 0px 7px 0px 17px;
  }
  @media screen and (min-width: 768px) {
    height: auto;
    padding: 0px 7px 0px 11px;
  }
  @media screen and (min-width: 991px) {
    height: auto;
    padding: 0px;
  }

  .signin-icon {
    width: 20px;
    height: 21px;
    background: variables.$background-lakeshore-sprites -100px 0 / 200px 200px;
    margin: 0px;
    @media screen and (min-width: 768px) {
      width: 26px;
      height: 28px;
      background: variables.$background-lakeshore-sprites -130px 0 / 260px 265px;
    }
    @media screen and (min-width: 992px) {
      width: 45px;
      height: 45px;
      display: block;
      background: variables.$background-lakeshore-sprites 0 1px / 450px 450px;
      &:hover {
        background: variables.$background-lakeshore-sprites -45px 1px / 450px
          450px;
      }
    }
  }
  .text-icon {
    font-size: 13px;
    line-height: 13px;
    margin-top: 6px;
    text-align: center;
    max-width: 87px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: color.$white-color;
    display: none;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
  &:hover {
    .flyout-modal {
      visibility: visible;
      opacity: 1;
    }
  }
}
.flyout-modal {
  visibility: hidden;
  top: 21px;
  opacity: 0;
  @include breakpoint('medium') {
    top: 46px;
  }
}
.padding-btn {
  padding: 11px 22px 10px;
  font-size: 16px;
  @include breakpoint('medium') {
    padding: 5px 22px 4px;
    font-size: 14px;
  }
}

.rewards-menu-section {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid color.$thin-gray;
  margin-bottom: 15px;
  a.learn-more-text {
    color: color.$primary-color;
    text-decoration: underline;
  }
  li {
    margin: 0;
    &:last-child {
      a {
        &::after {
          content: '';
        }
      }
    }
    a {
      font-size: 11px;
      color: color.$blue-royal;
      font-weight: typography.$bold;
      position: relative;
      &::after {
        content: '|';
        position: absolute;
        right: -6px;
        top: 0;
      }
    }
  }
}

.popOverContent-menus {
  button {
    background: transparent;
    border: none;
    padding: 0;
  }
  a.sign-out-button {
    color: color.$black;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: typography.$light;
    &:hover {
      text-decoration: underline;
    }
  }
  li {
    align-items: flex-start;
    margin-bottom: 13px;
    list-style: none;
    a,
    button {
      color: color.$black-color;
      font-size: 14px;
      text-decoration: none;
      text-align: left;
      font-weight: typography.$light;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.welcome-text {
  display: block;
  color: color.$black-color;
  @include breakpoint('medium') {
    font-size: 15px;
  }
}

.user-reward-section {
  padding: 10px 0;
  border-top: 1px solid color.$light-gray;
  border-bottom: 1px solid color.$light-gray;
  margin-bottom: 15px;
}

.user-reward-section a {
  text-align: center;
}

.user-reward-section img {
  margin: 0 auto 15px;
}

.rewards-points {
  display: block;
  font-size: 10px;
  font-weight: 700;
}

.reward-messages {
  font-size: 10px;
  color: color.$black-color;
}

.rewards-menu-section ul {
  padding: 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
  list-style: none;
}

.popOverContent button {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .popOverContent {
    left: auto;
    right: -45px;
  }
}
