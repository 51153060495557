
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.loader {
  width: 100%;
  max-width: 400px;
  margin-top: 25px;
}

.option {
  display: flex;
  margin-bottom: 10px;
}

.option-text {
  display: inline block;
  margin-left: 5px;
  font-weight: typography.$bold;
}
.option-text-store {
  width: 30%;
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-left: 5px;
}

.radio-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-bottom: 10px;
}

.text-loader {
  width: 80%;
  height: 15px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
  border-radius: 4px;
  margin-left: 25px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.radio-loader,
.text-loader {
  background: linear-gradient(90deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite;
}

.error-message {
  color: color.$red;
}
