
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.warning-alert-wrapper {
  border: 1px solid #ccc;
  margin-top: 26px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 8px;
  min-height: 46px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 30px 10px 10px;
}

.warning-alert-message {
  font-size: 12px;
  font-weight: typography.$light;
  line-height: 14px;
  cursor: pointer;
  strong {
    font-weight: 700;
  }
}

.icn-warning-sld:before {
  content: '\e953';
  color: #f39502;
  font-size: 25px;
}

.warning-bages-modal-content {
  p {
    font-size: 14px;
    line-height: 22px;
    a {
      color: color.$primary-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
