
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.releted-item-wrapper {
  h2 {
    border-top: 1px solid #ccc;
    padding-top: 20px;
  }
}
.recent-view-wrapper {
  h2 {
    border-top: 1px solid #ccc;
    padding-top: 20px;
  }
}
.pdp-carousels {
  min-height: 350px;
  .item {
    @include breakpoint('large') {
      min-height: 285px;
      margin-bottom: 30px;
    }
  }
}
.ll_btn_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;
  .choose_options_btn {
    width: auto;
    padding: 5px 10px;
    min-width: 75px;
    font-weight: 700;
    font-size: 12px;
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    border: 2px solid transparent;
    white-space: nowrap;
    transition: all 0.1s ease-in-out;
    touch-action: manipulation;
    position: inherit;
    color: color.$white-color;
    background-color: color.$danger-btn-color;
    border-color: color.$danger-btn-color;

    &:hover {
      transform: scaleY(1.08);
      background-color: color.$danger-btn-color;
      border-color: color.$danger-btn-hover-color;
    }
  }
}

.action-btn-wrp {
  text-align: center;
  .add-to-cart-btn,
  button {
    background-color: color.$red;
    color: color.$white-color;
    border: none;
    padding: 8px 10px;
    font-size: 12px;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 5px;
    &:hover {
      background-color: color.$danger-btn-hover-color;
    }
    @include breakpoint('medium') {
      padding: 7px 12px;
    }
  }
}

// .carousel {
//   border-bottom: 1px solid color.$light-gray;
// }
.carousel-items {
  display: flex;
  flex-direction: column;
  padding: 0 15px 20px;
  width: 50%;
  justify-content: space-between;
  @include breakpoint('medium') {
    width: 25%;
  }
  &.justify-content {
    justify-content: space-between;
  }
  &:last-child {
    padding-right: 0;
  }
  .product-name {
    max-width: 180px;
    .store-pickup {
      font-size: 12px;
      margin-bottom: -10px;
      display: block;
      color: color.$primary-color;
      margin-top: 7px;
    }
    a {
      display: block;
      min-height: 180px;
      @include breakpoint('medium') {
        min-height: 150px;
      }
    }
    .product-price {
      line-height: 18px;
    }
    .product-price-gsa {
      color: color.$green;
    }
    .product-price-sale {
      color: color.$red;
    }
    .product-price-original {
      display: flex;
      flex-direction: column;
    }
    .product-price-reg {
      font-size: 0.86em;
      line-height: 18px;
    }
  }

  .item-name {
    display: block;
    margin-top: 10px;
    color: color.$black-color;
    font-weight: typography.$bold;
    font-size: 16px;
    &.item-name-no-title {
      font-size: 14px;
    }
    @include breakpoint('medium') {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 2px;
    }
  }
  .product-detail {
    margin-top: 5px;
    max-width: 180px;
    .rating-count {
      a {
        font-size: 12px;
        color: color.$primary-color;
        vertical-align: super;
      }
    }
    .pre-sell-date {
      font-size: 12px;
      font-weight: 400;
      color: color.$black-color;
      margin-bottom: 3px;
    }
  }
}

.item-name {
  max-width: 180px;
  display: block;
}
.rating {
  display: flex;
}

.title {
  letter-spacing: -0.015em;
  margin: 13px 0px 16px;
  font-size: 22px;
  position: relative;
  line-height: 29px;
  font-weight: typography.$bold;
}
