
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
// DROPDOWN
.amount-section-wrap {
  position: relative;
  .gift-card-amount-title {
    margin-bottom: 10px;
    font-weight: 700;
  }
}
.gift-card-select-wrapper {
  position: relative;
}

.product-amount-select {
  margin-bottom: 20px;
  background-color: color.$white-color;
  border: 1px solid color.$light-gray;
  color: color.$black-color;
  font-size: 14px;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  padding: 8px 10px;
  font-weight: typography.$light;
  -webkit-appearance: none;
  option {
    position: relative;
    &::before {
      padding: 5px 15px;
    }
  }
}
.caret-select {
  position: absolute;
  right: 12px;
  top: 18px;
  z-index: 3;
  pointer-events: none;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
// GIFT CARD BOX
.gift-card-edit-box {
  background: color.$light-gray2;
  border: 1px solid color.$light-gray;
  margin-bottom: 14px;
  position: relative;
  padding: 12px 20px 11px;
  margin-top: 8px;
}

.gift-card-edit-box-wrap {
  input {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid color.$light-gray;
    font-weight: typography.$light;

    &:focus-visible {
      border-color: color.$light-blue-shadow;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
      outline: none;
    }
  }
}

//    PRIVACY POLICY
.see-privacy-policy-wrap {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  z-index: 2;
}

.see-privacy-policy {
  font-size: 13px;
  color: color.$primary-color;
  font-weight: typography.$light;

  &:hover {
    text-decoration: underline;
  }
}
.product-input {
  label {
    font-weight: typography.$light;
    margin-bottom: 5px;
  }
  input {
    font-size: 16px;
    @include breakpoint('small') {
      font-size: 14px;
    }
  }
}

.error {
  margin-bottom: 20px;
}
.Inputerror {
  input {
    border: 1px solid color.$red;
    &:focus {
      border-color: color.$red;
      -webkit-box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px color.$red4;
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 6px color.$red4;
    }
  }
}
.product-amount-input {
  position: relative;
  &::after {
    content: '.00';
    display: block;
    position: absolute;
    right: -28px;
    bottom: 5px;
  }
  label {
    margin-bottom: 5px;
  }
  input {
    font-size: 16px;
    @include breakpoint('small') {
      font-size: 14px;
    }
  }
}
.recipient-email,
.recipient-name,
.your-name {
  label {
    margin-bottom: 5px;
  }
  input {
    font-size: 16px;
    @include breakpoint('small') {
      font-size: 14px;
    }
  }
}
.message-option {
  margin-bottom: 5px;
}

.amount-decimal {
  display: block;
  position: absolute;
  right: -18px;
  bottom: 13px;
  color: color.$black-color;
  &:after {
    content: '.00';
  }
}
.gift-card-edit-box-wrap {
  .large-textarea {
    &:focus {
      border-color: color.$light-blue-shadow;
      outline: 0;
      -webkit-box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
      box-shadow:
        inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }
    font-size: 16px;
    color: #555;
    height: 34px;
    @include breakpoint('medium') {
      height: auto;
    }
    @include breakpoint('small') {
      font-size: 14px;
    }
  }
}

.quantity-wrapper {
  margin-bottom: 20px;
}
