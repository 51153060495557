
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.chart-cell {
  padding: 8px 10px 8px 0;
  flex-basis: 18%;
  text-align: center;
  &:first-child {
    flex-basis: 38%;
    text-align: left;
  }
  &:nth-child(2) {
    flex-basis: 28%;
  }
  &:nth-child(3) {
    flex-basis: 16%;
  }
  &:nth-child(3) {
    flex-basis: 18%;
  }
}

.coRelated-sizeChart-wrap {
  margin-bottom: 15px;
}
