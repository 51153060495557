
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-accessories {
  border: 1px solid color.$light-gray;
  margin-top: 20px;
  margin-bottom: 30px;
  border-bottom: 0px;
  .accessories-font {
    font-size: 14px;
    line-height: 22px;
    @include breakpoint('medium') {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.itemID-wrap {
  margin-top: 12px;
  @include breakpoint('medium') {
    margin-top: 0;
  }
}

.heading {
  font-size: 22px;
  font-weight: typography.$bold;
  background-color: color.$grey-12;
  border-bottom: 1px solid color.$light-gray;
  padding: 11px 15px;
}

.row {
  padding: 25px;
}

.name {
  font-size: 14px;
  font-weight: typography.$bold;
  margin-bottom: 0;

  @include breakpoint('medium') {
    font-size: 16px;
  }

  a {
    color: color.$primary-color;

    &:hover {
      text-decoration: underline;
    }
  }
}

.price {
  span {
    font-weight: typography.$bold;
    font-size: 14px;
  }
}

.border-bottom {
  margin: 0px;
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.add-to-list {
  margin: 15px 0;
  display: flex;
}
.accessories-add-list-modal {
  margin-top: 70px;
}

.earn-rewards {
  width: 100%;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
}
.error-text {
  color: color.$red;
}
