
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-personalization-wrapper {
  margin-top: 15px;
  margin-bottom: 10px;

  p {
    margin-bottom: 15px;
  }
  .s-icon {
    line-height: 16px;
  }
  .heading {
    font-weight: typography.$bold;
    margin-bottom: 5px;
    display: block;
    @include breakpoint('medium') {
      margin-bottom: 0;
    }
  }
  form {
    display: flex;
    gap: 5px;
    align-items: center;
    flex: 1;
    > div {
      width: 80%;
      @include breakpoint('medium') {
        width: 50%;
      }
    }
  }
  input {
    color: color.$grey10;
    font-weight: typography.$light;
    height: 34px;
    font-size: 16px;
    @include breakpoint('small') {
      font-size: 14px;
    }
  }
}
