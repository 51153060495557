
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.modal-deleted {
  width: 40%;
}

.modal-footer {
  padding: 20px;
}
