
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.save-message {
  font-size: 14px;
  color: color.$ternary-btn-color;
  font-weight: typography.$bold;
  margin-bottom: 10px;
}

.store-price-msg {
  margin-bottom: 10px;
  line-height: 19px;
  .special-msg {
    color: color.$red-neon;
  }
  .special-store-pickup-msg {
    font-size: 14px;
  }
}
