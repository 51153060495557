
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-filter {
  margin-bottom: 20px;
  display: flex;
  align-items: baseline;
}

.product-filter-div-wrapper {
  display: none;
  @include breakpoint('large') {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.product-filter-div {
  font-size: 12px;
  position: relative;
  background-color: color.$gray3;
  border: 1px solid color.$gray7;
  border-radius: 20px;
  margin-left: 10px;
  padding: 3px 10px;
  cursor: pointer;
  &:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  &:hover {
    button {
      color: color.$black3;
    }
  }
}

.product-filter-text {
  display: none;
  @include breakpoint('large') {
    text-transform: uppercase;
    font-weight: typography.$bold;
    font-size: 13px;
    display: flex;
    margin-right: 28px;
    display: block;
  }
}

.product-filter-button,
.product-filter-close {
  background: none;
  border: none;
}

.product-filter-button {
  padding-left: 0;
}

.product-filter-close {
  margin-left: 4px;
  background: none;
  border: none;
  padding: 0;
  height: 11px;
  vertical-align: middle;
}

.product-clear-all {
  color: color.$primary-color;
  margin-left: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.icn-x-close-lgt {
  font-size: 11px;

  &::before {
    content: '\e957';
    position: relative;
    top: -6px;
  }
}
