
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.icnHelpOtl {
  margin-left: 5px;
  color: color.$black-color;
  cursor: pointer;
  &::before {
    content: '\e927';
  }
}

.staySignPopOverText {
  cursor: pointer;
}

.narrowAvailability {
  border-radius: 8px;
  width: 270px;
  margin-right: 30px;
  @include breakpoint('large') {
    min-height: 177px;
  }
}

.storeAvailableHide {
  display: none;
}

.store-pick-up-modal-wrapper {
  .store-pick-up-modal {
    margin: 20px;
    max-width: 100%;
    @include breakpoint('medium') {
      margin: 70px auto 0;
      max-width: 600px;
    }
  }
}

.store-pick-up-modal-description {
  margin-bottom: 15px;
  display: block;
  a {
    color: color.$primary-color;
    &:hover {
      text-decoration: underline;
    }
  }
}
.store-info-icon {
  position: relative;
  top: -4px;
  left: -7px;
}
