
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.plp-assortment-resultList {
  margin: 25px 0;
}

.plp-resultList {
  margin-top: 0;
  @include breakpoint('medium') {
    margin-top: 25px;
  }
}
