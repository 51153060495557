
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.item-qty-wrapper {
  max-width: 60px;
  input[type='number'] {
    width: 70px;
  }
  select {
    width: auto;
    padding-right: 30px;
  }
  .qty-label {
    font-size: 17px;
    line-height: 27px;
    @include breakpoint('medium') {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.custom-qty-field {
  input {
    text-align: center;
    width: 70px;
    height: 38px;
  }
}

.out-of-stock-online {
  color: color.$red;
}
.select-option {
  button {
    min-width: 62px;
  }
  button.select-qty-button {
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}
