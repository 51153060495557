
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.product-terms-condition-link {
  font-size: 12px;
  color: color.$primary-color;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 20px;
  font-weight: typography.$light;

  &:hover {
    text-decoration: underline;
  }
}

.product-terms-condition-description {
  max-height: 70vh;
  overflow-y: scroll;
  margin-right: -16px;
  padding-right: 16px;
  padding-top: 7px;
  border-bottom: 20px solid color.$white-color;
  p {
    font-size: 14px;
    color: color.$black-color;
    margin-bottom: 5px;
  }
  a {
    color: color.$primary-color;
    &:hover {
      text-decoration: underline;
    }
  }
  ul {
    padding-left: 35px;

    li {
      list-style: outside;
    }
  }
}
.terms-conditions-modal-dialog {
  max-width: 600px;
}
