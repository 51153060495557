
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.error-text {
  color: color.$red;
}

.registering-success-modal {
  max-width: 600px;
  @include breakpoint('medium') {
    margin-left: auto;
    margin-right: auto;
  }
}

.modalContent {
  @include breakpoint('medium') {
    margin: 0 auto;
  }
  .modalHeader {
    padding: 20px;
    h4 {
      font-size: 22px;
      margin: 0px;
    }
  }
  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.015em;
  }
}
