
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.form-select-wrapper {
  margin-bottom: 20px;

  li {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .select-product {
    position: relative;
    font-size: 16px;
    padding: 0 0 10px 10px;
    border-bottom: 1px solid #ccc;
    margin: 0 -10px;
    font-weight: typography.$light;
    @include breakpoint('medium') {
      padding: 0 0 4px;
      font-size: 14px;
      margin: 0;
      border: 0;
    }
    .select-caret {
      top: 25%;
    }
  }
  .option-label-wrapper {
    padding: 4px 0;
    justify-content: space-between;
    font-size: 15px;
    &:hover {
      background-color: color.$gray-gainsboro;
    }
    @include breakpoint('medium') {
      font-size: 14px;
    }
    .option-value {
      text-align: right;
    }
    .price-black {
      color: color.$black;
    }
    .price-red {
      color: color.$red;
    }
    .price-green {
      color: color.$light-green;
    }
  }
  .option-label-wrapper.active {
    font-weight: typography.$bold;
  }
  .color-dropDown {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    top: 1px;
    @include breakpoint('medium') {
      margin-right: 5px;
      width: 14px;
      height: 14px;
      top: 1px;
    }
  }
  .white-color {
    box-shadow:
      -1px 0 color.$light-gray,
      0 1px color.$light-gray,
      1px 0 color.$light-gray,
      0 -1px color.$light-gray;
  }
  .multi-color {
    background-image: url(https://img.lakeshorelearning.com/is/image/OCProduction/assorted);
    background-size: 18px 18px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
  }
}

.pdp-dropdown-wrap {
  position: relative;
  .showDropdownError {
    margin-bottom: 20px;
    color: color.$red-neon;
  }
}
.pdp-chart-wrap {
  position: absolute;
  right: 0;
  > span {
    &:nth-child(2) {
      position: relative;
      padding-left: 7px;
      &::before {
        content: '';
        height: 50%;
        width: 1px;
        position: absolute;
        left: -1px;
        top: 6px;
        background: color.$black;
      }
    }
  }
}
.pdp-single-chart-wrap {
  > span {
    &:nth-child(2) {
      position: relative;
      padding-left: 7px;
      &::before {
        content: '';
        height: 50%;
        width: 1px;
        position: absolute;
        left: -1px;
        top: 6px;
        background: color.$black;
      }
    }
  }
}

.dropDown-label-status {
  display: flex;
  justify-content: space-between;
}

.dropDown-status {
  align-items: flex-end;
  color: color.$red;
  font-size: 12px;
}
