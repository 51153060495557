
    @use "styles/typography";
    @use "styles/color";
    @use "styles/variables";
    @import "styles/breakpoints";
    
.backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: 0;
  z-index: 9999;
  bottom: 0;
  left: 0;
  @include breakpoint('medium') {
    display: none;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  @include breakpoint('large') {
    width: 294px;
  }
  label {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .dropdownButton {
    width: 100%;
    height: 50px;
    background-color: color.$white-color;
    border: 1px solid color.$light-gray;
    border-radius: 4px;
    color: #020202;
    text-align: left;
    font-weight: 400;
    padding: 6px 10px;
    cursor: pointer;
    outline: none;
    transition:
      border 0.3s,
      background-color 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    @include breakpoint('small') {
      font-size: 14px;
      padding: 18px 10px;
      font-weight: typography.$light;
      height: 34px;
    }
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0px;
    vertical-align: middle;
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    @include breakpoint('small') {
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }

  .dropdownMenu {
    position: fixed;
    width: 90%;
    background-color: color.$white-color;
    border-radius: 0px;
    margin-top: 0px;
    list-style: none;
    padding: 0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 9;
    overflow-x: scroll;
    border: 0px solid color.$light-gray;
    bottom: 0px;
    margin-left: 4px;
    z-index: 9999;
    opacity: 0;
    transform: translateY(100%);
    transition: 0.3s all ease-in-out;
    pointer-events: none;
    @include breakpoint('medium') {
      top: 32px;
      width: 100%;
      height: 224px;
      position: absolute;
      left: -9px;
      border: 2px solid color.$grey1;
      border-radius: 4px;
      margin-left: 9px;
    }

    .dropdownItem {
      cursor: pointer;
      transition: background-color 0.2s;
      font-size: 16px;
      color: #000;
      margin-bottom: 20px;
      @include breakpoint('medium') {
        font-size: 14px;
      }
      @include breakpoint('large') {
        margin-bottom: 0px;
      }
      &:last-child {
        margin-bottom: 15px;
      }
      &:first-child {
        position: sticky;
        top: 0px;
        background: color.$white-color;
        margin-bottom: 0px;
        &:hover {
          background: color.$white-color;
        }
      }
      .default {
        padding: 0px 12px;
        width: 100%;
        display: block;
        @include breakpoint('large') {
          padding: 7px 12px;
        }
      }
      .label {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        padding: 15px 12px 15px 12px;
        font-weight: typography.$light;
        border-bottom: 1px solid color.$light-gray;
        margin-bottom: 10px;
        align-items: center;
        @include breakpoint('medium') {
          border-bottom: none;
          font-size: 15px;
          padding: 8px 12px 8px 12px;
        }
        @include breakpoint('large') {
          padding: 8px 12px 0px 12px;
        }
      }
      &:hover {
        background-color: color.$gray-gainsboro;
      }
    }
  }

  &.close .dropdownMenu {
    animation: slideOut 0.2s ease-in forwards;
    @include breakpoint('medium') {
      display: none;
      animation: none;
      opacity: 0;
    }
  }

  &.open .dropdownButton {
    border: 1px solid color.$grey1;
  }

  &.open .dropdownMenu {
    animation: slideIn 0.2s ease-out forwards;
    pointer-events: all;
    @include breakpoint('medium') {
      display: block;
      opacity: 1;
      animation: none;
      transform: translateY(0);
    }
    @include breakpoint('large') {
      width: 398px;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
